import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'
import { SelectOption } from '@curran-catalog/curran-atomic-library'

import { ObjAddonState } from './furniture-product'
import {
  AreaPricesResponse,
  BorderResponse,
  ObjFlooringWeave,
  RugPadResponse,
  WallToWallAreaPricesResponse,
  WeaveParametersResponse,
  WeaveStartingAtPriceResponse,
} from '@services/prices'
import {
  ContentfulObjFlooringBorder,
  ContentfulObjFlooringBorderColor,
  ContentfulObjFlooringRugpad,
  ContentfulObjFlooringWeaveColor,
  CustomizeType,
  FurniturePrices,
  ObjFlooringCornerStyle,
  ObjFlooringProductionRush,
} from 'types'
import { MeasureUnitKey as WallToWallMeasureUnitKey } from '@utils/measures'

enum RectangularMeasureUnitKey {
  WIDTH_CENTIMETER = 'widthCm',
  WIDTH_FEET = 'widthFt',
  WIDTH_INCHES = 'widthIn',
  LENGTH_CENTIMETER = 'lengthCm',
  LENGTH_FEET = 'lengthFt',
  LENGTH_INCHES = 'lengthIn',
}

enum CircularMeasureUnitKey {
  DIAMETER_CENTIMETER = 'diameterCm',
  DIAMETER_FEET = 'diameterFt',
  DIAMETER_INCHES = 'diameterIn',
}

export type CustomizeFieldKey = RectangularMeasureUnitKey | CircularMeasureUnitKey | WallToWallMeasureUnitKey

export type PreviewConfirmKeys =
  | 'type'
  | 'weaveColor'
  | 'size'
  | 'border'
  | 'addons'
  | 'notes'
  | 'isBorderCollapseComplete'
  | 'isAddonsCollapseComplete'

export type Customize = {
  type: CustomizeType
  isComplete: boolean
  isMetric: boolean
  weaveColor?: ContentfulObjFlooringWeaveColor
  unitMeasure?: SelectOption
} & { [key in CustomizeFieldKey]?: string }

export type Border = {
  isComplete: boolean
  borderMaterial?: ContentfulObjFlooringBorder & { borderMaterialPrice?: BorderResponse }
  borderColor?: ContentfulObjFlooringBorderColor
  borderWidth?: number
  cornerStyle?: ObjFlooringCornerStyle
}

export type Addons = {
  isComplete: boolean
  rugpad?: ContentfulObjFlooringRugpad & { rugpadPrice?: RugPadResponse }
  supplies: ObjAddonState[]
  productionRush?: ObjFlooringProductionRush
}

export type Notes = {
  isComplete: boolean
  optionalNotes?: string
}

export type PreviewConfirm = {
  [key in PreviewConfirmKeys]: boolean
}

export interface RugBuilderState {
  product?: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }
  activeStep: number

  customeStepFilled: boolean
  borderStepFilled: boolean

  customize: Customize
  border: Border
  addons: Addons
  notes: Notes
  previewConfirm: PreviewConfirm

  areaPrices?: AreaPricesResponse
  w2wPrices?: WallToWallAreaPricesResponse

  hasApiErrors: boolean

  subTotalPrice: number

  discountPrice: number

  subTotalDiscountPrice: number

  hasDimensionError?: boolean

  userTradeLevel: string | undefined

  defaultTradeLevel?: string | undefined

  rugpadPrice: number

  baseWeavePrice: number

  addOnPrice: number
}

export enum RugBuilderActionTypes {
  GET_FLOORING_PRODUCT = '@get-flooring-product',
  HANDLE_NEXT_STEP = '@handle-next-step',
  HANDLE_PREV_STEP = '@handle-prev-step',

  RESET_ALL_STEPS = '@reset-customize-type-step',
  SET_CUSTOMIZE_TYPE = '@set-customize-type',
  SET_CUSTOMIZE_WEAVE_COLOR = '@set-customize-weave-color',
  SET_CUSTOMIZE_SIZE_FIELDS = '@set-customize-size-fields',
  RESET_CUSTOMIZE_SIZE_FIELDS = '@reset-customize-size-fields',
  SET_CUSTOMIZE_IS_METRIC = '@set-customize-is-metric',
  SELECT_UNIT_MEASURE = '@select-unit-measure',
  HANDLE_CUSTOMIZE_IS_COMPLETE = '@handle-customize-is-complete',

  RESET_BORDER_STEP = '@reset-border-step',
  SET_BORDER_MATERIAL = '@set-border-material',
  SET_BORDER_COLOR = '@set-border-color',
  SET_BORDER_WIDTH = '@set-border-width',
  SET_CORNER_STYLE = '@set-corner-style',
  HANDLE_BORDER_IS_COMPLETE = '@handle-border-is-complete',

  RESET_ADDONS_STEP = '@reset-addons-step',
  SET_RUGPAD = '@set-rugpad',
  SET_SUPPLY = '@set-supply',
  REMOVE_SUPPLY = '@remove-supply',
  SET_PRODUCTION_RUSH = '@set-production-rush',
  HANDLE_ADDONS_IS_COMPLETE = '@handle-addons-is-complete',

  RESET_NOTES_STEP = '@reset-notes-step',
  SET_NOTES_FIELD = '@handle-notes-field',
  HANDLE_NOTES_IS_COMPLETE = '@handle-notes-is-complete',

  HANDLE_PREVIEW_CONFIRM_COLLAPSE = '@handle-preview-confirm-collapse',
  RESET_PREVIEW_CONFIRM_STEP = '@reset-preview-confirm-step',
  RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE = '@reset-preview-confirm-border-collapse',
  RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE = '@reset-preview-confirm-addons-collapse',

  HANDLE_API_ERRORS = 'handle-api-errors',
  SET_AREA_PRICES = '@set-area-prices',
  SET_WALL_TO_WALL_PRICES = ' @set-wall-to-wall-prices',

  SET_SUB_TOTAL_PRICE = '@set-sub-total-price',
  RESET_SUB_TOTAL_PRICE = '@reset-sub-total-price',

  CUSTOME_STEP_FILLED = '@custome-step-filled',
  BORDER_STEP_FILLED = '@border-step-filled',

  HANDLE_DIMENSION_ERROR = '@handle-dimension-error',

  SET_USER_TRADE_LEVEL = '@set-user-trade-level',
}

export interface RugBuilderAction {
  type: RugBuilderActionTypes
  productPayload?: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }

  isCustomeStepFilled?: boolean
  isBorderStepFilled?: boolean

  isCompletePayload?: boolean
  hasApiErrorPayload?: boolean

  stepPayload?: number

  //API responses
  areaPricesPayload?: AreaPricesResponse
  w2wPricesPayload?: WallToWallAreaPricesResponse
  userTradeLevelPayload?: string

  // Step 1
  customizeTypePayload?: CustomizeType
  fieldKeyPayload?: CustomizeFieldKey
  selectOptionPayload?: SelectOption
  handleDimensionErrorPayload?: boolean
  // Step 1 and 4
  fieldValuePayload?: string
  weaveColorPayload?: ContentfulObjFlooringWeaveColor

  // Step 2
  borderMaterialPayload?: ContentfulObjFlooringBorder & { borderMaterialPrice?: BorderResponse }
  borderColorPayload?: ContentfulObjFlooringBorderColor
  borderWidthPayload?: number
  cornerStylePayload?: ObjFlooringCornerStyle

  // Step 3
  rugpadPayload?: ContentfulObjFlooringRugpad & { rugpadPrice?: RugPadResponse }
  supplyPayload?: ObjAddonState
  productionRushPayload?: ObjFlooringProductionRush

  // Step 5
  keyPreviewConfirmPayload?: PreviewConfirmKeys
  valuePreviewConfirmPayload?: boolean
}

const initialCustomizeState: Customize = {
  type: CustomizeType.RECTANGULAR,
  isComplete: false,
  isMetric: false,
}

const initialBorderState: Border = { isComplete: false }
const initialAddonsState: Addons = { isComplete: false, supplies: [] }
const initialNotesState: Notes = { isComplete: false }
const initialPreviewConfirmState: PreviewConfirm = {
  type: false,
  weaveColor: false,
  size: false,
  border: false,
  addons: false,
  notes: false,

  isBorderCollapseComplete: true,
  isAddonsCollapseComplete: true,
}

const INITIAL_STATE: RugBuilderState = {
  product: undefined,
  activeStep: 0,

  customeStepFilled: false,
  borderStepFilled: false,

  customize: initialCustomizeState,
  border: initialBorderState,
  addons: initialAddonsState,
  notes: initialNotesState,
  previewConfirm: initialPreviewConfirmState,

  hasApiErrors: false,
  subTotalPrice: 0,
  hasDimensionError: true,
  discountPrice: 0,
  subTotalDiscountPrice: 0,
  userTradeLevel: '',
  addOnPrice: 0,
  rugpadPrice: 0,
  baseWeavePrice: 0,
}

export type RugBuilderContextType = {
  state: RugBuilderState
  dispatch: Dispatch<RugBuilderAction>
}

export const RugBuilderContext = createContext<RugBuilderContextType>({
  state: INITIAL_STATE,
  dispatch: () => ({}),
})

const rugBuilderReducer = (state: RugBuilderState, action: RugBuilderAction): RugBuilderState => {
  switch (action.type) {
    case RugBuilderActionTypes.GET_FLOORING_PRODUCT: {
      return {
        ...state,
        product: action.productPayload,
      }
    }
    case RugBuilderActionTypes.HANDLE_NEXT_STEP: {
      return {
        ...state,
        activeStep: state.activeStep >= 3 ? 3 : state.activeStep + (action.stepPayload ?? 1),
      }
    }

    case RugBuilderActionTypes.HANDLE_PREV_STEP: {
      return {
        ...state,
        activeStep: state.activeStep - (action.stepPayload ?? 1),
      }
    }

    // Api Responses
    case RugBuilderActionTypes.HANDLE_API_ERRORS: {
      return {
        ...state,
        hasApiErrors: action.hasApiErrorPayload ?? false,
      }
    }

    // dimension error
    case RugBuilderActionTypes.HANDLE_DIMENSION_ERROR: {
      return {
        ...state,
        hasDimensionError: action.handleDimensionErrorPayload ?? false,
      }
    }

    case RugBuilderActionTypes.SET_AREA_PRICES: {
      return {
        ...state,
        areaPrices: action.areaPricesPayload,
      }
    }

    case RugBuilderActionTypes.SET_WALL_TO_WALL_PRICES: {
      return {
        ...state,
        w2wPrices: action.w2wPricesPayload,
      }
    }

    case RugBuilderActionTypes.SET_USER_TRADE_LEVEL: {
      return {
        ...state,
        userTradeLevel: action.userTradeLevelPayload,
        defaultTradeLevel: action.userTradeLevelPayload,
      }
    }

    // Customize Step 1
    case RugBuilderActionTypes.RESET_ALL_STEPS: {
      return INITIAL_STATE
    }

    case RugBuilderActionTypes.SET_CUSTOMIZE_TYPE: {
      return {
        ...state,
        customize: { ...state.customize, type: action.customizeTypePayload ?? CustomizeType.RECTANGULAR },
      }
    }

    case RugBuilderActionTypes.SET_CUSTOMIZE_WEAVE_COLOR: {
      return {
        ...state,
        customize: { ...state.customize, weaveColor: action.weaveColorPayload },
      }
    }

    case RugBuilderActionTypes.SET_CUSTOMIZE_IS_METRIC: {
      return {
        ...state,
        customize: {
          ...state.customize,
          isMetric: !state.customize.isMetric,
        },
      }
    }

    case RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS: {
      return {
        ...state,
        customize: {
          ...state.customize,
          [action.fieldKeyPayload ?? '']: action.fieldValuePayload,
        },
      }
    }

    case RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS: {
      return {
        ...state,
        customize: {
          ...state.customize,
          sqm: '',
          lm: '',
          sqyd: '',
          sqft: '',
          lft: '',
          lftIn: '',
          diameterCm: '',
          diameterFt: '',
          diameterIn: '',
          lengthIn: '',
          lengthFt: '',
          lengthCm: '',
          widthIn: '',
          widthFt: '',
          widthCm: '',
        },
      }
    }

    case RugBuilderActionTypes.SELECT_UNIT_MEASURE: {
      return {
        ...state,
        customize: {
          ...state.customize,
          unitMeasure: action.selectOptionPayload,
        },
      }
    }

    case RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE: {
      return {
        ...state,
        customize: {
          ...state.customize,
          isComplete: action.isCompletePayload ?? false,
        },
      }
    }

    case RugBuilderActionTypes.CUSTOME_STEP_FILLED: {
      return {
        ...state,
        customeStepFilled: action.isCustomeStepFilled ?? false,
      }
    }

    // Border Step 2
    case RugBuilderActionTypes.RESET_BORDER_STEP: {
      return {
        ...state,
        border: initialBorderState,
      }
    }

    case RugBuilderActionTypes.SET_BORDER_MATERIAL: {
      return {
        ...state,
        border: {
          ...state.border,
          borderMaterial: action.borderMaterialPayload,
        },
      }
    }

    case RugBuilderActionTypes.SET_BORDER_COLOR: {
      return {
        ...state,
        border: {
          ...state.border,
          borderColor: action.borderColorPayload,
        },
      }
    }

    case RugBuilderActionTypes.SET_BORDER_WIDTH: {
      return {
        ...state,
        border: {
          ...state.border,
          borderWidth: action.borderWidthPayload,
        },
      }
    }

    case RugBuilderActionTypes.SET_CORNER_STYLE: {
      return {
        ...state,
        border: {
          ...state.border,
          cornerStyle: action.cornerStylePayload,
        },
      }
    }

    case RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE: {
      return {
        ...state,
        border: {
          ...state.border,
          isComplete: action.isCompletePayload ?? false,
        },
      }
    }

    case RugBuilderActionTypes.BORDER_STEP_FILLED: {
      return {
        ...state,
        borderStepFilled: action.isBorderStepFilled ?? false,
      }
    }

    // Addons Step 3
    case RugBuilderActionTypes.RESET_ADDONS_STEP: {
      return {
        ...state,
        addons: {
          ...initialAddonsState,
        },
      }
    }

    case RugBuilderActionTypes.SET_RUGPAD: {
      return {
        ...state,
        addons: {
          ...state.addons,
          rugpad: action.rugpadPayload,
        },
      }
    }

    case RugBuilderActionTypes.SET_SUPPLY: {
      return {
        ...state,
        addons: {
          ...state.addons,
          supplies: action.supplyPayload ? [...state.addons.supplies, action.supplyPayload] : state.addons.supplies,
        },
      }
    }

    case RugBuilderActionTypes.REMOVE_SUPPLY: {
      return {
        ...state,
        addons: {
          ...state.addons,
          supplies: state.addons.supplies.filter(
            (supply) => supply.CONTENTFUL_ID !== action.supplyPayload?.CONTENTFUL_ID,
          ),
        },
      }
    }

    case RugBuilderActionTypes.SET_PRODUCTION_RUSH: {
      return {
        ...state,
        addons: {
          ...state.addons,
          productionRush: action.productionRushPayload,
        },
      }
    }

    case RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE: {
      return {
        ...state,
        addons: {
          ...state.addons,
          isComplete: action.isCompletePayload ?? false,
        },
      }
    }

    // Notes Step 4
    case RugBuilderActionTypes.RESET_NOTES_STEP: {
      return {
        ...state,
        notes: initialNotesState,
      }
    }

    case RugBuilderActionTypes.SET_NOTES_FIELD: {
      return {
        ...state,
        notes: {
          ...state.notes,
          optionalNotes: action.fieldValuePayload,
        },
      }
    }

    case RugBuilderActionTypes.HANDLE_NOTES_IS_COMPLETE: {
      return {
        ...state,
        notes: {
          ...state.notes,
          isComplete: action.isCompletePayload ?? false,
        },
      }
    }

    case RugBuilderActionTypes.SET_SUB_TOTAL_PRICE: {
      const tradeDiscountPercent = () => {
        const total =
          (state.w2wPrices?.price ?? 0) +
          (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
          (state.border.cornerStyle?.price ?? 0) +
          (state.addons.rugpad?.rugpadPrice?.price ?? 0) +
          state.addons.supplies.reduce(
            (acc, curr) =>
              curr.addonPrices &&
              Array.isArray((curr.addonPrices as FurniturePrices).products) &&
              (curr.addonPrices as FurniturePrices).products.length > 0
                ? acc + Number((curr.addonPrices as FurniturePrices).products[0].price ?? 0)
                : acc + 0,
            0,
          ) +
          (state.addons.productionRush?.price ?? 0)

        if (state.defaultTradeLevel == 'silver') {
          if (total >= 25000) {
            return 0.4
          }
          if (total >= 15000) {
            return 0.3
          }
          return 0.2
        } else if (state.defaultTradeLevel == 'gold') {
          if (total >= 25000) {
            return 0.4
          }
          return 0.3
        } else if (state.defaultTradeLevel == 'platinum') {
          return 0.4
        } else {
          return 0
        }
      }

      const tradeLevel = () => {
        const total =
          (state.w2wPrices?.price ?? 0) +
          (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
          (state.border.cornerStyle?.price ?? 0) +
          (state.addons.rugpad?.rugpadPrice?.price ?? 0) +
          state.addons.supplies.reduce(
            (acc, curr) =>
              curr.addonPrices &&
              Array.isArray((curr.addonPrices as FurniturePrices).products) &&
              (curr.addonPrices as FurniturePrices).products.length > 0
                ? acc + Number((curr.addonPrices as FurniturePrices).products[0].price ?? 0)
                : acc + 0,
            0,
          ) +
          (state.addons.productionRush?.price ?? 0)
        if (state.defaultTradeLevel == 'silver') {
          if (total >= 25000) {
            return 'platinum'
          }
          if (total >= 15000) {
            return 'gold'
          }
          return 'silver'
        } else if (state.defaultTradeLevel == 'gold') {
          if (total >= 25000) {
            return 'platinum'
          }
          return 'gold'
        } else if (state.defaultTradeLevel == 'platinum') {
          return 'platinum'
        } else {
          return ''
        }
      }

      return {
        ...state,
        userTradeLevel: tradeLevel(),
        subTotalPrice:
          (state.w2wPrices?.price ?? 0) +
          (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
          (state.border.cornerStyle?.price ?? 0) +
          (state.addons.rugpad?.rugpadPrice?.price ?? 0) +
          state.addons.supplies.reduce(
            (acc, curr) =>
              curr.addonPrices &&
              Array.isArray((curr.addonPrices as FurniturePrices).products) &&
              (curr.addonPrices as FurniturePrices).products.length > 0
                ? acc + Number((curr.addonPrices as FurniturePrices).products[0].price ?? 0)
                : acc + 0,
            0,
          ) +
          (state.addons.productionRush?.price ?? 0),
        discountPrice:
          ((state.w2wPrices?.price ?? 0) +
            (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
            (state.border.cornerStyle?.price ?? 0) +
            (state.addons.rugpad?.rugpadPrice?.price ?? 0)) *
          tradeDiscountPercent(),
        subTotalDiscountPrice:
          (state.w2wPrices?.price ?? 0) +
          (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
          (state.border.cornerStyle?.price ?? 0) +
          (state.addons.rugpad?.rugpadPrice?.price ?? 0),
        rugpadPrice: state.addons.rugpad?.rugpadPrice?.price ?? 0,
        baseWeavePrice:
          (state.w2wPrices?.price ?? 0) +
          (state.border.borderMaterial?.borderMaterialPrice?.price ?? 0) +
          (state.border.cornerStyle?.price ?? 0),
        addOnPrice: state.addons.supplies.reduce(
          (acc, curr) =>
            curr.addonPrices &&
            Array.isArray((curr.addonPrices as FurniturePrices).products) &&
            (curr.addonPrices as FurniturePrices).products.length > 0
              ? acc + Number((curr.addonPrices as FurniturePrices).products[0].price ?? 0)
              : acc + 0,
          0,
        ),
      }
    }

    case RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE: {
      return {
        ...state,
        subTotalPrice: 0,
      }
    }

    // Preview & Confirm Step 5
    case RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_STEP: {
      return {
        ...state,
        previewConfirm: initialPreviewConfirmState,
      }
    }

    case RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE: {
      return {
        ...state,
        previewConfirm: {
          ...state.previewConfirm,
          [action.keyPreviewConfirmPayload ?? '']: action.valuePreviewConfirmPayload ?? false,
        },
      }
    }

    case RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE: {
      return {
        ...state,
        border: { ...initialBorderState, isComplete: action.isCompletePayload ?? false },
      }
    }

    case RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE: {
      return {
        ...state,
        addons: {
          ...initialAddonsState,
          isComplete: action.isCompletePayload ?? false,
        },
      }
    }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

export const RugBuilderProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(rugBuilderReducer, INITIAL_STATE)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <RugBuilderContext.Provider value={value}>{children}</RugBuilderContext.Provider>
}
