import axios from '@utils/axios'

export type Vendors = {
  name: string
  address_1?: string
  address_2?: string
  city?: string
  state?: string | null
  zip_code?: string
  country?: string
  lat?: string
  long?: string
  email?: string
  description?: string
  phone?: string
  website?: string
}
export const getAllVendors = async () => {
  try {
    const request = await axios.get<Vendors[]>(`/api/vendors`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return request
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
