import { APIProvider, AdvancedMarker, InfoWindow, Map, Pin } from '@vis.gl/react-google-maps'

import { useEffect, useState } from 'react'
import React from 'react'
import { Vendors, getAllVendors } from '../services/vendors'
import { AtIcon } from '@curran-catalog/curran-atomic-library'

const GoogleMaps = () => {
  const [vendors, setVendors] = useState<Vendors[]>([])
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [currVendor, setCurrVendor] = useState<Vendors>()
  const [map, setMap] = useState<google.maps.Map>()

  // Email, Phone Number and Website are optional fields
  // Description is an optional fields
  useEffect(() => {
    if (vendors.length == 0) {
      getAllVendors().then((response) => setVendors(response?.data ? response?.data : []))
    }
  })

  const getInfoWindow = () => {
    if (currVendor) {
      const itemToFocus = document.getElementById(currVendor.name) as HTMLElement
      itemToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' })
      if (map?.getCenter()?.lat() != Number(currVendor?.lat)) {
        map?.setCenter({ lat: Number(currVendor?.lat), lng: Number(currVendor?.long) })
        map?.setZoom(7)
      }

      return (
        <InfoWindow
          position={{ lat: Number(currVendor.lat), lng: Number(currVendor.long) }}
          shouldFocus={true}
          pixelOffset={[0, -40]}
          onClose={function () {
            setCurrVendor(undefined)
          }}
        >
          <h1>
            <b>{currVendor.name}</b>
          </h1>
          <p>
            {currVendor.address_1} {currVendor.address_2}
          </p>
          <p>
            {currVendor.city}
            {currVendor.city && (currVendor.zip_code || currVendor.state) ? ',' : ''} {currVendor.state}
            {currVendor.state && currVendor.zip_code ? ',' : ''} {currVendor.zip_code}
          </p>
          <p>{currVendor.country}</p>
          <p>{currVendor.description}</p>
          <div className="sm:hidden text-xs flex flex-col justify-start items-start gap-1 pb-1 px-2">
            <div className={`flex flex-row items-start	${!currVendor.phone ? 'hidden' : ''}`}>
              <AtIcon type="phone" size={20}></AtIcon>
              <div className="underline">
                <a
                  className="hover:text-blue-400 font-normal text-blue-700 
"
                  href={`tel:${currVendor.phone}`}
                >
                  {currVendor.phone ?? ''}
                </a>
              </div>
            </div>
            <div className={`flex flex-row items-start	${!currVendor.email ? 'hidden' : ''}`}>
              <AtIcon type="mail" size={20}></AtIcon>
              <div className="underline">
                <a
                  className="hover:text-blue-400 font-normal text-blue-700 
"
                  href={`mailto:${currVendor.email}`}
                >
                  {currVendor.email ?? ''}
                </a>
              </div>
            </div>
            <div className={`flex flex-row items-start	${!currVendor.website ? 'hidden' : ''}`}>
              <AtIcon type="info" size={20}></AtIcon>
              <div className=" underline">
                <a
                  target="_blank"
                  className="hover:text-blue-400 font-normal text-blue-700 
"
                  href={`http://www.${currVendor.website}`}
                >
                  {currVendor.website ?? ''}
                </a>
              </div>
            </div>
          </div>
        </InfoWindow>
      )
    }
    return
  }
  return (
    <div>
      <div className="text-center text-xl md:text-3xl text-secondary py-1 font-sans">Where to Buy</div>
      <div className="flex flex-col-reverse sm:flex-row gap-2">
        <div className="overflow-y-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300 h-[100px] w-full sm:h-[500px] overflow-x-auto sm:w-1/3 md:w-1/4 xl:w-1/5">
          <div className="flex flex-row sm:flex-col sm:h-fit sm:w-9/10 w-full">
            {vendors.map(function (vendor) {
              return (
                <button
                  key={'Button' + vendor.name}
                  id={vendor.name}
                  className={`flex flex-col items-center mt-1  ${
                    vendor.name == currVendor?.name ? 'bg-slate-300 shadow-md' : 'bg-slate-200'
                  }`}
                  onClick={function () {
                    if (currVendor?.name == vendor.name) {
                      setCurrVendor(undefined)
                      setShowInfo(false)
                    }
                    setCurrVendor(vendor)
                    setShowInfo(true)
                  }}
                >
                  <div className=" py-1 px-1 w-[200px] sm:w-9/10 h-fit">
                    <h1>
                      <b>{vendor.name}</b>
                    </h1>
                    <div className="text-xs">
                      <p>
                        {vendor.city}
                        {vendor.city && (vendor.zip_code || vendor.state) ? ',' : ''} {vendor.state}
                        {vendor.state && vendor.zip_code ? ',' : ''} {vendor.zip_code}
                      </p>
                      <p>{vendor.country}</p>
                    </div>
                  </div>
                  {currVendor?.name != vendor.name ? (
                    <div className="text-xs flex flex-row justify-center items-start gap-1 pb-1 px-2">
                      <div className={`flex flex-row items-center	${!vendor.phone ? 'hidden' : ''}`}>
                        <AtIcon type="phone" size={20}></AtIcon>
                      </div>
                      <div className={`flex flex-row items-center	${!vendor.email ? 'hidden' : ''}`}>
                        <AtIcon type="mail" size={20}></AtIcon>
                      </div>
                      <div className={`flex flex-row items-center	${!vendor.website ? 'hidden' : ''}`}>
                        <AtIcon type="info" size={20}></AtIcon>
                      </div>
                    </div>
                  ) : (
                    <div className="text-xs flex flex-row sm:flex-col justify-center items-center gap-1 pb-1 px-2">
                      <div className={`flex flex-row items-center	${!vendor.phone ? 'hidden' : ''}`}>
                        <AtIcon type="phone" size={20}></AtIcon>
                        <div className="sm:flex hidden underline">
                          <a
                            className="hover:text-blue-400 font-normal text-blue-700 
"
                            href={`tel:${vendor.phone}`}
                          >
                            {vendor.phone ?? ''}
                          </a>
                        </div>
                      </div>
                      <div className={`flex flex-row items-center	${!vendor.email ? 'hidden' : ''}`}>
                        <AtIcon type="mail" size={20}></AtIcon>
                        <div className="sm:flex hidden underline">
                          <a
                            className="hover:text-blue-400 font-normal text-blue-700 
"
                            href={`mailto:${vendor.email}`}
                          >
                            {vendor.email ?? ''}
                          </a>
                        </div>
                      </div>
                      <div className={`flex flex-row items-center	${!vendor.website ? 'hidden' : ''}`}>
                        <AtIcon type="info" size={20}></AtIcon>
                        <div className="sm:flex hidden underline">
                          <a
                            target="_blank"
                            className="hover:text-blue-400 font-normal text-blue-700 
"
                            href={`http://www.${vendor.website}`}
                          >
                            {vendor.website ?? ''}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </button>
              )
            })}
          </div>
        </div>

        <div className="justify-centered sm:w-2/3 md:w-3/4 xl:w-4/5 sm:h-[500px] h-[350px] w-full">
          <APIProvider apiKey="AIzaSyAvQ58atxkyPgN7phgujyWT1vMwXmMmssw">
            <Map
              mapId="1baabd3103db2e64"
              zoomControl={true}
              style={{
                margin: 'auto',
                width: '100%',
                height: '100%',
              }}
              defaultZoom={3}
              defaultBounds={{
                north: 49.38,
                south: 7.12,
                west: 170.15,
                east: 52.74,
              }}
              reuseMaps={true}
              minZoom={2}
              maxZoom={10}
              colorScheme="LIGHT"
              streetViewControl={false}
              mapTypeControl={false}
              fullscreenControl={false}
              keyboardShortcuts={false}
              onCenterChanged={(ev) => {
                if (!map) {
                  setMap(ev.map)
                }
              }}
            >
              {vendors.map(function (vendor) {
                return (
                  <AdvancedMarker
                    key={'Marker' + vendor.name}
                    position={{ lat: Number(vendor.lat), lng: Number(vendor.long) }}
                    onClick={() => {
                      if (currVendor?.name == vendor.name) {
                        setCurrVendor(undefined)
                        setShowInfo(false)
                      }
                      setCurrVendor(vendor)

                      setShowInfo(true)
                    }}
                  >
                    <Pin background="#FBBC04" glyphColor="#000" borderColor="#000" />
                  </AdvancedMarker>
                )
              })}
              {showInfo && getInfoWindow()}
            </Map>
          </APIProvider>
        </div>
      </div>
    </div>
  )
}

export default GoogleMaps
